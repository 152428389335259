import { GetStaticProps } from 'next';

import SEO from '@/components/global/SEO';
import Layout from '@/components/global/Layout';
import Servicos from '@/components/Pages/Index/Servicos';
import HeroSection from '@/components/Pages/Index/HeroSection';
import CidadesAtendidasVisita from '@/components/Pages/Index/CidadesAtendidasVisita';
import VantagensDelps from '@/components/global/VantagensDelps';
import ConhecaADelps from '@/components/global/ConhecaADelps';

import api from '@/services/graphCMS';

import { PageWrapper, PageContainer, SectionWrapper } from '@/styles/Page';
import { VantagensWrapper, ConhecaADelpsWrapper } from '@/styles/Pages';

interface IProps {
  slides: {
    frase?: string;
    foto: any;
    id: string;
  }[];
}

const IndexPage: React.FC<IProps> = ({ slides }): JSX.Element => {
  return (
    <>
      <SEO title="Página Inicial" description="Encurtando distâncias" />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              <Servicos />
            </SectionWrapper>
            <SectionWrapper>
              <HeroSection slides={slides} />
            </SectionWrapper>
            <SectionWrapper>
              <CidadesAtendidasVisita />
            </SectionWrapper>
            <VantagensWrapper>
              <VantagensDelps />
            </VantagensWrapper>
            <ConhecaADelpsWrapper>
              <ConhecaADelps />
            </ConhecaADelpsWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default IndexPage;

export const getStaticProps: GetStaticProps = async () => {
  const { data } = await api.post('', {
    query: `query{
      slidesDaHome {
        id
        frase
        foto {
          url
        }
      }
    }
    `,
  });

  return {
    props: { slides: data.data.slidesDaHome },
    revalidate: 10000,
  };
};
