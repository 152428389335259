import { colors } from '@/styles/GlobalStyle';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1360px;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;

  margin-top: 45px;

  max-height: 520px;
  min-height: 195px;

  div {
    max-height: 520px;
    min-height: 195px;
    width: 100%;

    img {
      object-fit: cover;
      height: 600px;
      border-radius: 10px;

      @media (max-width: 800px) {
        height: 350px;
      }

      @media (max-width: 650px) {
        height: 250px;
      }
    }
  }

  @media (max-width: 1440px) {
    padding: 0 40px;
  }

  @media (max-width: 850px) {
    margin-top: 24px;
  }

  @media (max-width: 499px) {
    padding: 0 16px;
  }
`;

export const CarouselContainer = styled(Carousel)`
  border-radius: 10px;
  .carousel-status {
    display: none !important;
  }

  border-radius: 10px;

  .carousel.carousel-slider .control-arrow:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

export const CarouselItem = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  .text {
    position: absolute;

    display: flex;
    align-items: center;
    text-align: start;

    margin-left: 10%;
    z-index: 300;

    h2 {
      color: white;
      font-size: 67px;
      line-height: 69px;
      font-family: 'Exo 2';
      max-width: 332px;
      font-weight: 700;
      font-style: italic;
      flex: none;
    }

    @media (max-width: 650px) {
      h2 {
        font-size: 32px;
        line-height: 34px;
      }
    }
  }
`;

export const CustomArrow = styled.button<{ isLeft?: boolean }>`
  background: ${colors.gray700};
  height: 60px;
  width: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;

  top: calc(50% - 30px);
  left: ${({ isLeft }) => (isLeft ? '0' : 'initial')};
  right: ${({ isLeft }) => (isLeft ? 'initial' : '0')};

  position: absolute;

  outline: none;
  border: none;
  z-index: 2;

  cursor: pointer;

  transition: background 0.2s;

  &:hover {
    background: ${colors.gray600};
  }

  @media (max-width: 750px) {
    display: none;
  }
`;
