import { useEffect, useState } from 'react';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Container, Servico } from './styles';

import Rastreamento from '../../../../images/icons/rastreamento';
import Cotacao from '../../../../images/icons/cotacao.svg';
import Coleta from '../../../../images/icons/coleta.svg';
import ViaBoleto from '../../../../images/icons/2-via-boleto.svg';
import Dacte from '../../../../images/icons/dacte.svg';
import XML from '../../../../images/icons/xml.svg';

const Servicos = (): JSX.Element => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return (
    <Container>
      {mounted && (
        <Swiper spaceBetween={23} slidesPerView={'auto'}>
          <SwiperSlide>
            <Link href="/rastreamento" passHref>
              <Servico>
                <Rastreamento />
                Rastreamento
              </Servico>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Servico
              href="https://api.whatsapp.com/send?phone=5562996461252&text=Preciso%20de%20uma%20cota%C3%A7%C3%A3o%20de%20frete"
              target="_blank"
              rel="noreferrer"
            >
              <Cotacao />
              Fazer cotação
            </Servico>
          </SwiperSlide>
          <SwiperSlide>
            <Servico
              href="https://api.whatsapp.com/send?phone=556299851496&text=Preciso%20marcar%20uma%20coleta"
              target="_blank"
              rel="noreferrer"
            >
              <Coleta />
              Solicitar coleta
            </Servico>
          </SwiperSlide>
          <SwiperSlide>
            <Servico
              href="https://ssw.inf.br/2/servico?id=53&"
              target="_blank"
              rel="noreferrer"
            >
              <ViaBoleto />
              2ª via de boleto
            </Servico>
          </SwiperSlide>
          <SwiperSlide>
            <Servico
              href="https://ssw.inf.br/2/servico?id=51&"
              target="_blank"
              rel="noreferrer"
            >
              <Dacte />
              Emitir DACTE
            </Servico>
          </SwiperSlide>
          <SwiperSlide>
            <Servico
              href="https://ssw.inf.br/2/servico?id=52&"
              target="_blank"
              rel="noreferrer"
            >
              <XML />
              Baixar XML
            </Servico>
          </SwiperSlide>
        </Swiper>
      )}
    </Container>
  );
};

export default Servicos;
