import Link from 'next/link';
import Image from 'next/image';

import { Container, Section } from './styles';

import { RiArrowRightSLine } from 'react-icons/ri';

import Logo from '../../../images/components/ConhecaADelps/logo.svg';
import Picture from '@/images/components/ConhecaADelps/picture.jpg';

const ConhecaADelps = (): JSX.Element => {
  return (
    <Container>
      <Section>
        <Image
          src={Picture}
          alt="Foto de funcionários da Delps em frente aos caminhões"
          quality={100}
          placeholder="blur"
        />
        <div className="mobile-container">
          <div className="text">
            <h2>Conheça a Delps</h2>
            <Link href="/a-delps" passHref>
              <a>
                Por que fazemos o que fazemos <RiArrowRightSLine size={16} />
              </a>
            </Link>
          </div>
          <Logo className="mobile" viewBox="0 0 181 146" />
        </div>
        <Logo className="desktop" viewBox="0 0 181 146" />
      </Section>
    </Container>
  );
};

export default ConhecaADelps;
