import styled from 'styled-components';
import { SectionWrapper } from '../Page';

export const VantagensWrapper = styled(SectionWrapper)`
  margin-top: 45px;
`;

export const ConhecaADelpsWrapper = styled(SectionWrapper)`
  margin: 45px 0;
`;
