import Image from 'next/image';

import Imagem1 from '@/images/Pages/Index/HeroSection/imagem1.png';
import Imagem2 from '@/images/Pages/Index/HeroSection/imagem2.png';
import Imagem3 from '@/images/Pages/Index/HeroSection/imagem3.png';
import Imagem4 from '@/images/Pages/Index/HeroSection/imagem4.png';

import {
  Container,
  CarouselContainer,
  CustomArrow,
  CarouselItem,
} from './styles';

import { ImArrowRight2, ImArrowLeft2 } from 'react-icons/im';

interface IArrow {
  onClick: any;
  isLeft?: boolean;
}

interface IProps {
  slides: Array<{
    frase?: string;
    foto: any;
    id: string;
  }>;
}

const HeroSection: React.FC<IProps> = ({ slides }): JSX.Element => {
  return (
    <Container>
      <CarouselContainer
        infiniteLoop
        autoPlay
        swipeable
        emulateTouch
        showThumbs={false}
        interval={5000}
        renderArrowNext={(onClickHandler) => <Arrow onClick={onClickHandler} />}
        renderArrowPrev={(onClickHandler) => (
          <Arrow onClick={onClickHandler} isLeft />
        )}
      >
        {slides.map(({ foto, frase, id }) => (
          <CarouselItem key={id}>
            <Image
              src={foto.url}
              alt="Foto de caminhões da Delps realizando uma viagem"
              quality={100}
              width={1360}
              height={520}
            />
            {!!frase && <div className="text"><h2>{frase}</h2></div>}
          </CarouselItem>
        ))}
        {/* <CarouselItem>
          <Image
            src={Imagem1}
            alt="Foto de caminhões da Delps realizando uma viagem"
            quality={100}
            placeholder="blur"
          />
        </CarouselItem>
        <CarouselItem>
          <Image
            src={Imagem2}
            alt="Foto de caminhões da Delps realizando uma viagem"
            quality={100}
            placeholder="blur"
          />
        </CarouselItem>
        <CarouselItem>
          <Image
            src={Imagem3}
            alt="Foto de caminhões da Delps realizando uma viagem"
            quality={100}
            placeholder="blur"
          />
        </CarouselItem>
        <CarouselItem>
          <Image
            src={Imagem4}
            alt="Foto de caminhões da Delps realizando uma viagem"
            quality={100}
            placeholder="blur"
          />
        </CarouselItem> */}
      </CarouselContainer>
    </Container>
  );
};

const Arrow: React.FC<IArrow> = ({ isLeft, onClick, ...rest }): JSX.Element => {
  return (
    <CustomArrow
      {...rest}
      onClick={onClick}
      isLeft={isLeft}
      aria-label={
        isLeft ? 'Botão para foto passada' : 'Botão para próxima foto'
      }
    >
      {isLeft ? <ImArrowLeft2 size={30} /> : <ImArrowRight2 size={30} />}
    </CustomArrow>
  );
};

export default HeroSection;
