import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  max-width: 1360px;

  margin-top: 45px;

  gap: 23px;

  .swiper-container {
    width: auto;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    width: 220px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  @media (max-width: 1440px) {
    padding-left: 40px;
  }

  @media (max-width: 850px) {
    margin-top: 24px;
  }

  @media (max-width: 499px) {
    padding-left: 16px;
  }
`;

export const Servico = styled.a`
  width: 220px;
  height: 80px;

  background: ${colors.orange400};
  border-radius: 12px;

  transition: background 0.3s;

  &:hover {
    background: ${colors.orange500};
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  flex: none;

  color: white;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Sora';
  font-weight: 400;

  box-shadow: 0px 3px 10px rgba(255, 70, 0, 0.36);
`;
