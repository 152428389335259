import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1360px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;

  @media (max-width: 850px) {
    margin-top: 24px;
  }
`;

export const Section = styled.section`
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;

  flex-wrap: wrap;
  gap: 40px;

  @media (max-width: 1440px) {
    padding: 0 40px;
  }

  @media (max-width: 499px) {
    padding: 0 16px;
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 660px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  gap: 20px;

  flex: 1 1 560px;

  > svg,
  rect {
    height: 100% !important;
    width: 100% !important;
  }

  .text {
    margin-left: 60px;
    padding: 20px 0;

    flex: none;

    @media (max-width: 550px) {
      padding: 15px 0;
      height: 100%;
    }
  }

  h2 {
    color: white;
    font-family: 'Exo 2';
    max-width: 256px;
  }

  a {
    align-items: center;
    display: inline-block;
    font-family: 'Sora';
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    margin: 0;
    margin-top: 74px;

    transition: 0.15s;

    :hover {
      text-decoration: underline;
    }

    svg {
      vertical-align: middle;
    }
  }

  @media (max-width: 1256px) {
    .text {
      flex: initial;
    }
  }

  @media (max-width: 430px) {
    flex-direction: column;

    .text {
      margin: 0 20px;
    }

    a {
      margin-top: 30px;
    }
  }
`;

export const CidadesAtendidas = styled(Card)`
  background: ${colors.orange400};
  box-shadow: 0px 5px 10px rgba(250, 76, 7, 0.3);

  h2 {
    font-size: 44px;
    font-weight: 700;
    font-style: italic;
    line-height: 45px;
  }

  > div {
    flex: 1 1 200px;
  }

  img {
    max-height: 350px !important;
    min-height: 350px !important;
    margin-right: -10px !important;
    object-fit: cover;
  }

  a {
    max-width: 252px;
  }

  @media (max-width: 775px) {
    h2 {
      font-size: 36px;
      line-height: 37px;
    }

    a {
      max-width: 200px;
    }
  }

  @media (max-width: 629px) {
    h2 {
      font-size: 28px;
      line-height: 29px;
    }

    img {
      max-width: 180px !important;
      margin: auto !important;
      min-height: 100% !important;
    }
  }

  @media (max-width: 540px) {
    align-items: flex-start;
    .text {
      margin-left: 16px;
    }
    h2 {
      font-size: 24px;
      line-height: 25px;
    }
  }

  @media (max-width: 430px) {
    align-items: flex-start;

    a {
      max-width: 220px;
    }

    .text {
      max-width: initial;
      padding-top: 16px;
      margin-left: 16px;
    }
    a {
      max-width: initial;
      margin-top: 20px;
    }

    > div:not(.text) {
      flex: initial;
      align-self: center;
      img {
        max-width: 230px !important;
      }
    }

    h2 {
      max-width: initial;
    }
  }

  @media (max-width: 340px) {
    a {
      max-width: 180px;
    }
  }
`;

export const Visita = styled(Card)`
  background: ${colors.gray800};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  /* > div {
    flex: 1 1 200px;
  } */

  h2 {
    font-size: 42px;
    font-weight: 700;
    font-style: italic;
    line-height: 43px;
  }

  a {
    max-width: 168px;
  }

  img {
    max-height: 350px !important;
    min-height: 350px !important;
    margin-right: -20px !important;
    object-position: 0;
    object-fit: cover;
  }

  @media (max-width: 775px) {
    h2 {
      font-size: 36px;
      line-height: 37px;
    }

    a {
      max-width: 200px;
    }
  }

  @media (max-width: 629px) {
    max-height: 250px;

    a {
      max-width: 120px;
    }

    img {
      max-width: 250px !important;
      margin: auto !important;
      min-height: 100% !important;
      object-fit: cover;
      object-position: 0;
    }

    > div {
      flex: 0.3 0.3 250px;
    }
  }

  @media (max-width: 540px) {
    align-items: flex-start;

    .text {
      margin-left: 16px;
    }

    h2 {
      font-size: 24px;
      line-height: 25px;
    }
  }

  @media (max-width: 430px) {
    max-height: initial;
    align-items: flex-start;

    > div:not(.text) {
      flex: initial;
      align-self: center;
      img {
        max-width: 230px !important;
      }
    }

    .text {
      margin-left: 16px;
    }
    a {
      max-width: 250px;
    }

    h2 {
      font-size: 33px;
      line-height: 34px;
      max-width: initial;
    }
  }
`;
