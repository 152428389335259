import Image from 'next/image';
import Link from 'next/link';

import AssistenteVirtual from '@/images/components/VantagensDelps/assistente-virtual.png';
import AppRastreamento from '@/images/components/VantagensDelps/app-rastreamento.png';
import Entrega24h from '@/images/components/VantagensDelps/entrega-24h.png';
import AmplaEstrutura from '@/images/components/VantagensDelps/ampla-estrutura.png';
import EmbarquesDiarios from '@/images/components/VantagensDelps/embarques-diarios.png';

import { Container, Vantagem, VantagensWrapper } from './styles';

const VantagensDelps = (): JSX.Element => {
  return (
    <VantagensWrapper>
      <Container>
        <a
          href="https://api.whatsapp.com/send?phone=556235861151&text=Ol%C3%A1"
          target="_blank"
          rel="noreferrer"
        >
          <Vantagem>
            <Image
              className="floating"
              src={AssistenteVirtual}
              alt="Imagem de um entregador e um celular com WhatsApp"
              quality={100}
              placeholder="blur"
            />
            <p>Assistente virtual via WhatsApp</p>
          </Vantagem>
        </a>
        <Link href="/rastreamento" passHref>
          <a>
            <Vantagem>
              <Image
                className="floating"
                src={AppRastreamento}
                alt="Imagem de uma caixa com uma localização em cima"
                quality={100}
                placeholder="blur"
              />
              <p>App de rastreamento</p>
            </Vantagem>
          </a>
        </Link>
        <Link href="/cidades-atendidas" passHref>
          <a>
            <Vantagem>
              <Image
                className="floating"
                src={Entrega24h}
                alt="Imagem de uma caixa com um relógio simbolizando 24 horas"
                quality={100}
                placeholder="blur"
              />
              <p>Entregas de 24 horas</p>
            </Vantagem>
          </a>
        </Link>
        <a href="https://logg5.app" target="_blank" rel="noreferrer">
          <Vantagem>
            <Image
              className="floating"
              src={AmplaEstrutura}
              alt="Imagem de um carrinho de mão carregando duas caixas"
              quality={100}
              placeholder="blur"
            />
            <p>Ampla estrutura de coleta</p>
          </Vantagem>
        </a>
        <Link href="/cidades-atendidas" passHref>
          <a>
            <Vantagem>
              <Image
                className="floating"
                src={EmbarquesDiarios}
                alt="Imagem de uma encomendo em uma porta"
                quality={100}
                placeholder="blur"
              />
              <p>Embarques diários para todas as filiais</p>
            </Vantagem>
          </a>
        </Link>
      </Container>
    </VantagensWrapper>
  );
};

export default VantagensDelps;
