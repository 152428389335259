import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const VantagensWrapper = styled.div`
  width: 100%;
  max-width: 1360px;
  display: flex;
  justify-content: center;

  @media (max-width: 1440px) {
    padding: 0 40px;
  }

  @media (max-width: 499px) {
    padding: 0 16px;
  }
`;

export const Container = styled.section`
  width: 100%;
  max-width: 1277px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  column-gap: 40px;
  row-gap: 30px;

  @media (max-width: 1050px) {
    max-width: 600px;
  }

  a:hover {
    p {
      color: ${colors.orange300};
    }
  }
`;

export const Vantagem = styled.div`
  width: 100%;
  max-width: 159px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover {
    .floating {
      animation-name: floating;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
  @keyframes floating {
    from {
      transform: translate(0, 0px);
    }
    65% {
      transform: translate(0, 7px);
    }
    to {
      transform: translate(0, -0px);
    }
  }

  p {
    color: black;
    font-family: 'Sora';
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    transition: 0.2s ease-in-out;
  }

  > div {
    position: relative;

    > div {
      padding-bottom: 35px !important;
    }

    img {
      object-fit: cover;
      height: initial !important;
      margin: 0 !important;
      min-height: initial !important;
    }

    ::before {
      content: '';
      position: absolute;
      bottom: 15px;
      left: 2.5%;
      background: rgba(0, 0, 0, 0.15);
      width: 95%;
      height: 10px;
      border-radius: 50%;
      z-index: 9;
      filter: blur(9px);
    }
  }
`;
