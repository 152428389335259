import Link from 'next/link';
import Image from 'next/image';

import { Container, Section, CidadesAtendidas, Visita } from './styles';

import { RiArrowRightSLine } from 'react-icons/ri';

import CidadesAtendidasImage from '@/images/Pages/Index/CidadesAtendidasVisita/cidades-atendidas.png';
import VisitaImage from '@/images/Pages/Index/CidadesAtendidasVisita/visita.png';

const CidadesAtendidasVisita = (): JSX.Element => {
  return (
    <Container>
      <Section>
        <CidadesAtendidas>
          <div className="text">
            <h2>Saiba onde e quando atuamos</h2>
            <Link href="/cidades-atendidas" passHref>
              <a>
                Confira as cidades atendidas <RiArrowRightSLine size={14} />
              </a>
            </Link>
          </div>
          <Image
            src={CidadesAtendidasImage}
            alt="Mapa com todas as cidades atendidas"
            quality={100}
            placeholder="blur"
            className="image"
          />
        </CidadesAtendidas>
        <Visita>
          <div className="text">
            <h2>Podemos visitar sua loja</h2>
            <Link href="/atendimento" passHref>
              <a>
                Solicite uma visita <RiArrowRightSLine size={14} />
              </a>
            </Link>
          </div>
          <Image
            src={VisitaImage}
            alt="Imagem de uma casa com caixas dentro"
            quality={100}
            placeholder="blur"
            className="image"
          />
        </Visita>
      </Section>
    </Container>
  );
};

export default CidadesAtendidasVisita;
